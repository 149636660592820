// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  display: flex;
  justify-content: flex-star;
  flex-direction: row-reverse;
  gap: 1rem;
  font-family: var(--main-font-light);
  cursor: pointer;
}
.buttons .button {
  font-size: var(--font-size-label-desktop);
  width: 210px;
}
.buttons .button.left {
  margin-right: 20px;
}
.buttons .ghost {
  width: 210px;
  height: 50px;
  margin-top: 1.5rem;
}
.buttons .ghost.left {
  margin-right: 20px;
}

.btnSave {
  margin-left: 87%;
  margin-top: 1.5%;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background-color: var(--main_button_color);
  font-size: 20px;
  color: white;
}
.btnSave:disabled {
  box-shadow: none;
  background: #ddd;
  opacity: 0.5;
  cursor: default;
}

.load-file {
  margin: auto;
  margin-right: 2px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.load-file button {
  width: auto;
  margin-left: 5px;
  padding: 10px;
}
.load-file .flex-button {
  display: flex;
}

.header-title {
  padding-bottom: 30px;
}

@media screen and (min-width: 600px) {
  .desktop {
    max-width: 82%;
    margin: 0 auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/employer-action/employer-action.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,0BAAA;EACA,2BAAA;EACA,SAAA;EACA,mCAAA;EACA,eAAA;AACF;AAAE;EACE,yCAAA;EACA,YAAA;AAEJ;AADI;EACE,kBAAA;AAGN;AAAE;EACE,YAAA;EACA,YAAA;EACA,kBAAA;AAEJ;AADI;EACE,kBAAA;AAGN;;AACA;EAEE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,0CAAA;EACA,eAAA;EACA,YAAA;AACF;AACE;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAGA;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAF;AAEE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;;AAIA;EACE,oBAAA;AADF;;AAIA;EACE;IACE,cAAA;IACA,cAAA;EADF;AACF","sourcesContent":[".buttons {\n  display: flex;\n  justify-content: flex-star;\n  flex-direction: row-reverse;\n  gap: 1rem;\n  font-family: var(--main-font-light);\n  cursor: pointer;\n  .button {\n    font-size: var(--font-size-label-desktop);\n    width: 210px;\n    &.left {\n      margin-right: 20px;\n    }\n  }\n  .ghost {\n    width: 210px;\n    height: 50px;\n    margin-top: 1.5rem;\n    &.left {\n      margin-right: 20px;\n    }\n  }\n}\n.btnSave {\n  // height: 25px;\n  margin-left: 87%;\n  margin-top: 1.5%;\n  border-radius: 4px;\n  cursor: pointer;\n  border: none;\n  background-color: var(--main_button_color);\n  font-size: 20px;\n  color: white;\n\n  &:disabled {\n    box-shadow: none;\n    background: #ddd;\n    opacity: 0.5;\n    cursor: default;\n  \n  }\n}\n.load-file {\n  margin: auto;\n  margin-right: 2px;\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 25px;\n  \n  button {\n    width: auto;\n    margin-left: 5px;\n    padding: 10px;\n  }\n  .flex-button{\n    display :flex;\n  }\n}\n\n.header-title{\n  padding-bottom: 30px;\n}\n\n@media screen and (min-width: 600px) {\n  .desktop {\n    max-width: 82%;\n    margin: 0 auto;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
