import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TasksService } from "src/app/services/tasks.service";
import { DataService } from "../../../services/data.service";
import { TextService } from "../../../services/text.service";
import { NoContentComponent } from "../../common/no-content/no-content.component";
import { TaskComponentComponent } from "../task-component/task-component.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor } from "@angular/common";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
@Component({
    selector: "app-employee-card-tasks",
    templateUrl: "./employee-card-tasks.component.html",
    styleUrls: ["./employee-card-tasks.component.scss"],
    standalone: true,
    imports: [NgIf, MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, FormsModule, NgFor, MatLegacyOptionModule, TaskComponentComponent, NoContentComponent, BreadcrumbsComponent]
})
export class EmployeeCardTasksComponent implements OnInit {
  @Input() employmentId = "";
  optionForStatusFilter = ["הכל", "תואמה", "סגור", "בתהליך"];
  selectedStauts;
  tasksData = ["", "", "", ""];
  showGhostElementsHeader = false;
  paginatorLength = 11;
  constructor(
    public dataService: DataService,
    public tasksService: TasksService,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.dataService.showGhostElements = true;
    this.showGhostElementsHeader = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.tasksService.getEmployeeTasks(this.employmentId, undefined).subscribe(
      (resp) => {
        this.dataService.showGhostElements = false;
        this.showGhostElementsHeader = false;
        this.dataService.showDataLoader = false;
        this.tasksData = resp["missions"];
        this.optionForStatusFilter = resp["filter"];
        this.optionForStatusFilter.forEach((element) => {
          if (element["label"] === "הכל") {
            this.selectedStauts = element;
          }
        });
      },
      (err) => {
        this.dataService.handleErrors(err);
        this.dataService.showGhostElements = false;
      }
    );

  }
  filterTasks(index) {
    this.tasksData = ["", "", "", ""];
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.tasksService
      .getEmployeeTasks(this.employmentId, this.selectedStauts["value"])
      .subscribe(
        (resp) => {
          this.dataService.showDataLoader = false;
          this.tasksData = resp["missions"];
          this.dataService.showGhostElements = false;
        },
        (err) => {
          this.dataService.showGhostElements = false;
          this.dataService.showDataLoader = false;
          this.dataService.handleErrors(err);
        }
      );
  }
}
