import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgxMaskService } from 'ngx-mask';
 
@Directive({
  selector: '[maskedInput]',
  standalone: true
 
})
export class MaskedInputDirective {
  @Input() maskedInput: string = '';
 
  constructor(private el: ElementRef, private maskService: NgxMaskService) { }
 
  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = this.el.nativeElement;
    const value = input.value;
    input.value = this.maskService.applyMask(value, this.maskedInput);
  }
}
 
 