import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewChecked,
} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { EmployeeService } from "src/app/services/employee.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NewAppointmentService } from "src/app/services/new-appointment.service";
import { PopupTypes } from "../../../models/popuptype";
import { TextService } from "../../../services/text.service";
import { Location, NgIf, NgClass, AsyncPipe } from "@angular/common";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { breadcrumbs } from "../../../utils/breadcrumbs-links";
import { Observable } from "rxjs";
import { FeedbackService } from "src/app/services/feedback.service";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { EmployeeCardFeedbackComponent } from "../../generic-components/employee-card-feedback/employee-card-feedback.component";
import { EmployeeCardTasksComponent } from "../../generic-components/employee-card-tasks/employee-card-tasks.component";
import { EmployeeCardPersonalDetailsComponentComponent } from "../../generic-components/employee-card-personal-details-component/employee-card-personal-details-component.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { log } from "node:console";
declare var $;
@Component({
    selector: "app-employee-card",
    templateUrl: "./employee-card.component.html",
    styleUrls: ["./employee-card.component.scss"],
    standalone: true,
    imports: [NgIf, BreadcrumbsComponent, NgClass, EmployeeCardPersonalDetailsComponentComponent, EmployeeCardTasksComponent, EmployeeCardFeedbackComponent, GeneralPopupComponent, AsyncPipe]
})
export class EmployeeCardComponent implements OnInit, AfterViewChecked {
  name;
  personalDetailTab = true;
  optionType = "personal";
  clickOnTab = false;
  employeeData;
  id_employee =
    "U2FsdGVkX18OzXMiXVBop9JhlFYlPtQyu7aK3jbvEkMSXcf38h5vYrep1L2u3SZVPhlNMn";
  name_title;
  id_title;
  newAppointment = PopupTypes.newAppointment;
  aprovedRequest = PopupTypes.approvedRequest;
  isExistsProcedure = PopupTypes.isExistsProcedure;
  breadcrumbsLinkNames = BreadcrumbsLinkNames;
  @ViewChild("employeeCard") targetElement: any;
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.MY_EMPLOYEES,
    BreadcrumbsLinkNames.EMPLOYEE_CARD,
  ];
  displayFeedback$: Observable<boolean>;

  constructor(
    private cdr: ChangeDetectorRef,
    public textService: TextService,
    public router: Router,
    public dataService: DataService,
    public employeeService: EmployeeService,
    public newAppointmentService: NewAppointmentService,
    private route: ActivatedRoute,
    private location: Location,
    private feedbackService: FeedbackService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    let vh1 = window.innerHeight;
    document.documentElement.style.setProperty("--vh1", `${vh1}px`);
    this.name = this.dataService.name;
    this.id_employee = this.route.snapshot.paramMap.get("id");

    if (this.id_employee === "") {
      this.router.navigate(["/my-employees"]);
    }
    this.dataService.showGhostElements = true;
    this.employeeService.showGhostElementsTabs = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.employeeService.getEmployee(this.id_employee).subscribe(
      (resp) => {
        this.dataService.showGhostElements = false;
        this.employeeService.showGhostElementsTabs = false;
        this.dataService.showDataLoader = false;
        this.employeeData = resp;

        this.name_title = this.dataService.getResourceText(
          this.textService.textSource["EMPLOYEE_CARD_SCREEN_TITLE"],
          this.employeeData
        );
        this.resizeNameFont();
        this.id_title = this.dataService.getResourceText(
          this.textService.textSource["EMPLOYEE_CARD_SCREEN_SUBTITLE"],
          this.employeeData
        );
        this.employeeService.employeeData = resp;
        this.employeeService.employeeDataArrived.next();
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );

    this.displayFeedback$ = this.feedbackService.getDisplayFeedbackObs();
  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  addHizunNavigateToBreadcrumbs(){
    this.breadcrumbsPages = [
      BreadcrumbsLinkNames.HOME,
      BreadcrumbsLinkNames.MY_EMPLOYEES,
      BreadcrumbsLinkNames.EMPLOYEE_CARD,
      BreadcrumbsLinkNames.FEEDBACK
    ];
  }
  addTaskNavigateToBreadcrumbs(){
    this.breadcrumbsPages = [
      BreadcrumbsLinkNames.HOME,
      BreadcrumbsLinkNames.MY_EMPLOYEES,
      BreadcrumbsLinkNames.EMPLOYEE_CARD,
      BreadcrumbsLinkNames.TASKS_
    ];
  }
  addNavigateToBreadcrumbs(){
    this.breadcrumbsPages = [
            BreadcrumbsLinkNames.HOME,
            BreadcrumbsLinkNames.MY_EMPLOYEES,
            BreadcrumbsLinkNames.EMPLOYEE_CARD,
            BreadcrumbsLinkNames.PERSONA_DETAILS
          ];
          // this.cdr.markForCheck();
          // console.log("aaa",this.breadcrumbsPages)
          
  }

  getEmployeeDetailAfterUpdate() {
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.employeeService.getEmployee(this.id_employee).subscribe(
      (resp) => {
        this.dataService.showGhostElements = false;
        this.dataService.showDataLoader = false;
        this.employeeData = resp;

        this.name_title = this.dataService.getResourceText(
          this.textService.textSource["EMPLOYEE_CARD_SCREEN_TITLE"],
          this.employeeData
        );
        this.resizeNameFont();
        this.id_title = this.dataService.getResourceText(
          this.textService.textSource["EMPLOYEE_CARD_SCREEN_SUBTITLE"],
          this.employeeData
        );
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );
  }

  employeeDataForNewApointment() {
    this.newAppointmentService.newAppointmentForSpecificEmployee = {
      employment_id: this.employeeData["employment_id"],
      id_number: this.employeeData["personal_details"]["id_number"],
      name: this.employeeData["personal_details"]["name"],
    };
  }

  focusOnPersonaltab() {
    setTimeout(() => {
      $(".tab")[0].focus();
    });
  }

  focusOnTasktab() {
    setTimeout(() => {
      $(".employee-card-tasks").focus();
    });
  }
  focusOnForm() {
    setTimeout(() => {
      $("#firstname").focus();
      $("#role").focus();
    });
  }

  resizeNameFont() {
    let width = window.innerWidth;
    let fontSize = 50;
    if (this.dataService.name == "ayalon") {
      fontSize = 40;
    }
    if (width < 600) {
      return;
    }
    let count = this.name_title.length;
    if (count < 40 && count > 30) {
      fontSize = fontSize - 10;
      $(".name").css("font-size", fontSize + "px");
    } else if (count < 50 && count > 39) {
      fontSize = fontSize - 25;
      $(".name").css("font-size", fontSize + "px");
    } else if (count < 60 && count > 49) {
      $(".name").css("font-size", "20px");
    } else if (count > 59) {
      $(".name").css("font-size", "15px");
    } else {
      $(".name").css("font-size", fontSize + "px");
    }
  }

  back() {
    this.location.back();
  }

  navigateTo(linkName) {
    const foundLink = breadcrumbs.find((breadc) => breadc.name === linkName);
    this.router.navigate([foundLink.link]);
  }
}
