import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";

@Component({
  selector: "app-monthly-hourly",
  templateUrl: "./monthly-hourly.component.html",
  styleUrls: ["./monthly-hourly.component.scss"],
  standalone: true,
})
export class MonthlyHourlyComponent {
  @Output() salaryMethodChange = new EventEmitter();
public showDialog:boolean=false
  name = this.dataService.name;
  constructor(
    public dataService: DataService,
    public textService: TextService,
    public router: Router
  ) {}
  Close(){
    this.showDialog=true;
    this.salaryMethodChange.emit(this.showDialog);

  }
}
