import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { DataService } from "src/app/services/data.service";
import { TextService } from "../../../services/text.service";
import { FeedbackService } from "src/app/services/feedback.service";
import { Observable } from "rxjs";
import { BusinessesForUserService } from "src/app/services/businesses-for-user.service";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { RouterLink } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { NgIf, NgFor, NgClass, AsyncPipe } from "@angular/common";
declare var $;
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  standalone: true,
  imports: [NgIf, MatSidenavModule, RouterLink, MatLegacyFormFieldModule, MatLegacySelectModule, NgFor, MatLegacyOptionModule, NgClass, AsyncPipe]
})
export class NavbarComponent implements OnInit {
  name: string = environment.name;
  @Input() areLoggedIn = true;
  @Input() whichURL: string;
  homePageIconLabel: string = "מעבר לדף הבית";
  isTheSideMenuOpen = false;
  displayFeedback$: Observable<boolean>;
  @Input() src;
  drawer;
  constructor(
    private dataService: DataService,
    private el: ElementRef,
    public textService: TextService,
    private feedbackService: FeedbackService,
    private readonly businessesForUserService: BusinessesForUserService
  ) {
    this.textService.setTextSource();
  }
  businesses$ = this.businessesForUserService.businesses$;
  selectedBussinessID$ = this.businessesForUserService.selectedBusinessID$;
  ngOnInit(): void {
    this.displayFeedback$ = this.feedbackService.getDisplayFeedbackObs();
  }

  backToSite() {
    this.dataService.logOut();
  }

  focusOnFirstElementInMenu() {
    setTimeout(() => {
      let element = this.el.nativeElement.querySelector(".header-logo");
      if (element) {
        element.focus();
      }
    }, 100);
    const body = document.body;
    body.classList.add("noscroll");
  }

  focusOnHumburgerMenu() {
    setTimeout(() => {
      let element = this.el.nativeElement.querySelector("#menu-button");
      if (element) {
        element.focus();
      }
    }, 100);
  }

  focusOnFirstElemntInTheContent(e) {
    switch (e.view.location.pathname) {
      case "/login":
      case "/login/debug":
        setTimeout(() => {
          $(".id").focus();
        });
        break;
      case "/sms":
        setTimeout(() => {
          $(".id").focus();
        });
        break;
      case "/accessibility":
      case "/privacy":
      case "/terms":
        setTimeout(() => {
          $("#info-page").focus();
        });
        break;
      case "/":
        setTimeout(() => {
          $(".home").focus();
        });
        break;
      case "/register":
        setTimeout(() => {
          $(".stage").focus();
        });
        break;
      case "/policy":
        setTimeout(() => {
          $(".policy").focus();
        });
        case "/action":
          setTimeout(() => {
            $(".action").focus();
          });
        break;
      case "/appointments":
        setTimeout(() => {
          $(".appointments").focus();
        });
        break;
      case "/tasks":
        setTimeout(() => {
          $(".tasks").focus();
        });
        break;
        case "/employee-card":
          setTimeout(() => {
            $(".employee-card").focus();
          });
          break;
      case "/my-employees":
        setTimeout(() => {
          $(".workers-page").focus();
        });
        break;
      case "/register-thank-you":
        setTimeout(() => {
          $(".thankyou").focus();
        });
        break;
      default:
        if (e.view.location.pathname.includes("/appointment-type")) {
          setTimeout(() => {
            $(".appointment-type-container").focus();
          });
        }
        if (e.view.location.pathname.includes("/task-type")) {
          setTimeout(() => {
            $(".type-task").focus();
          });
        }
        if (e.view.location.pathname.includes("/employee-card")) {
          setTimeout(() => {
            $(".employee-card").focus();
          });
        }
        break;
    }
  }

  removeNoScroll() {
    const body = document.body;
    body.classList.remove("noscroll");
  }
  changeSelectedBusiness(i) {
    this.businessesForUserService.changeBusiness(i, true).subscribe();
  }
}
